import './Main.css';

import { lazy } from 'react';

import Home from './components/Home/Home';
const Sponsors = lazy(() => import('./components/Sponsors/Sponsors'));
const AboutUs = lazy(() => import('./components/AboutUs/AboutUs'));
// const TestItNow = lazy(() => import('./components/TestItNow/TestItNow'));
const OurTeam = lazy(() => import('./components/OurTeam/OurTeam'));
// const Features = lazy(() => import('./components/Features/Features'));
const GetStarted = lazy(() => import('./components/GetStarted/GetStarted'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const Copyright = lazy(() => import('./components/Copyright/Copyright'));
const Navbar = lazy(() => import('./components/Navbar/Navbar'));
const HowItWorks = lazy(() => import('./components/HowItWorks/HowItWorks'));
const Pricing = lazy(() => import('./components/Pricing/Pricing'));
const Faq = lazy(() => import('./components/Faq/Faq'));
const ArtificialIntelligence = lazy(() => import('./components/ArtificialIntelligence/ArtificialIntelligence'));
const AIPricing = lazy(() => import('./components/AIPricing/AIPricing'));
const AdditionalServices = lazy(() => import('./components/AdditionalServices/AdditionalServices'));

function Main() {
  return (
    <>
      <Navbar />
      <Home />
      <Sponsors />
      <AboutUs />
      {/* <TestItNow /> */}
      <HowItWorks />
      <OurTeam />
      <Pricing />
      <Faq />
      <ArtificialIntelligence />
      <AIPricing />
      <AdditionalServices />
      <GetStarted />
      <Footer />
      <Copyright />
    </>
  );
}

export default Main;
